import { Controller } from 'stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  static targets = ['action', 'content']

  connect() {
    var $this = this

    if (this.hasActionTarget) {
      var clipboard = new ClipboardJS(this.actionTarget, {
        target: function (trigger) {
          return $this.contentTarget
        },
      })
      clipboard.on('success', function (e) {
        alert('Copied to your clipboard.')
      })
      clipboard.on('error', function (e) {
        alert('Could not copy - sorry!')
      })

      if (ClipboardJS.isSupported()) {
        this.actionTarget.classList.remove('hidden')
      }
    }
  }
}

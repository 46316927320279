import { Controller } from 'stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  static targets = ['action', 'urlaction']

  connect() {
    var $this = this

    var clipboard = new ClipboardJS(this.actionTarget)

    clipboard.on('success', function (e) {
      alert('Copied to your clipboard.')
    })
    clipboard.on('error', function (e) {
      alert('Could not copy - sorry!')
    })

    if (ClipboardJS.isSupported()) {
      this.actionTarget.classList.remove('hidden')
    }

    if(this.hasUrlactionTarget) {
      var urlClipboard = new ClipboardJS(this.urlactionTarget)

      urlClipboard.on('success', function (e) {
        alert('Copied to your clipboard.')
      })
      urlClipboard.on('error', function (e) {
        alert('Could not copy - sorry!')
      })

      if (ClipboardJS.isSupported()) {
        this.urlactionTarget.classList.remove('hidden')
      }
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectButton', 'form']
  static values = { collectionName: String }

  initialize() {
    var self = this

    var iframe = $('[data-oembed-type="video"] iframe:visible')
    if (iframe.length) {
      var video = iframe.attr('src')
      this.src = video
    }

    $(document).keydown(function (e) {
      if (e.keyCode === 27) {
        e.preventDefault()
        self.resetVideoPlayer()
        $('#overlay-video').removeClass('active')
      }
    })
  }

  openVideoPlayer() {
    $('body').addClass('modal-shown')
    $('.core_navbar').addClass('z-10')
    $('#overlay-video').addClass('active')

    setTimeout(
      function () {
        var iframe = $('[data-oembed-type="video"] iframe:visible')
        if (iframe.length) {
          var video = iframe.attr('src')
          iframe.attr('src', this.src + '&autoplay=1')
        }
      }.bind(this),
      200
    )
  }

  closeVideoPlayer() {
    if (
      !$(event.target).hasClass('overlay') &&
      !$(event.target).hasClass('overlay-button')
    )
      return

    event.preventDefault()
    $('#overlay-video').removeClass('active')
    $('body').removeClass('modal-shown')
    $('.core_navbar').removeClass('z-10')
    this.resetVideoPlayer()
  }

  resetVideoPlayer() {
    var iframe = $('[data-oembed-type="video"] iframe:visible')
    if (iframe.length) {
      var video = iframe.attr('src')
      iframe.attr('src', '')
    }
  }

  clickCollection(e) {
    mixpanel.track('Visited Collection Page', {
      'collectionName': this.collectionNameValue,
    }, {send_immediately: true});
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'joinSection',
    'modalManage',
  ]

  initialize() {
    $(document).keydown(function (e) {
      if (e.keyCode === 27) {
        $('.modal').removeClass('active')
        $('body').removeClass('modal-shown')
      }
    })
  }

  //
  showJoin(event) {
    event.preventDefault()

    var navbarHeight = $('.core_navbar').height()
    $('html, body').animate(
      { scrollTop: $(this.joinSectionTarget).offset().top - navbarHeight },
      'slow',
      'swing'
    )
  }

  showManage(event) {
    event.preventDefault()

    // open new modal
    $(this.modalManageTarget).addClass('active')
    $('body').addClass('modal-shown')
  }

  closeManageModal(event) {
    if (
      !$(event.target).hasClass('modal') &&
      !$(event.target).hasClass('close-modal-btn') &&
      !$(event.target).hasClass('close-modal')
    )
      return
    event.preventDefault()

    $('body').removeClass('modal-shown')
    $(this.modalManageTarget).removeClass('active')
  }

  scrollTo(event) {
    const id = $(event.currentTarget).data('id')
    $('html, body').animate(
      {
        scrollTop: $(`#section-${id}`).offset().top - 100,
      },
      700
    )
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String }
  connect() {
    if (this.hasUrlValue) {
      window.location.href = this.urlValue
    } else {
      window.location.reload()
    }
  }
}
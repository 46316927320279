import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "email",
    "emailError",
    "password",
    "passwordError",
    "newPassword",
    "newPasswordError",
    "newPasswordConfirm",
    "firstName",
    "firstNameError",
    "lastName",
    "lastNameError",
    "companyName",
    "companyNameError"
  ]
  static values = { 'minPassword': Number }

  submitForm(event) {
    if (this.hasEmailTarget) {
      this.checkValidEmail(event)
    }

    if (this.hasPasswordTarget) {
      this.checkValidPassword(event)
    }

    if (this.hasNewPasswordTarget) {
      this.checkValidNewPassword(event)
    }

    if (this.hasFirstNameTarget) {
      this.checkValidFirstName(event)
    }

    if (this.hasLastNameTarget) {
      this.checkValidLastName(event)
    }

    if (this.hasCompanyNameTarget) {
      this.checkValidCompanyName(event)
    }
  }

  fireAuthFailEvent() {
    window.setTimeout(() => {
      const event = new CustomEvent("auth-fail")
      window.dispatchEvent(event)
    }, 500)
  }

  checkValidEmail(event) {
    let submittedEmail = this.emailTarget.value
    this.emailErrorTarget.innerHTML = ""
    this.emailErrorTarget.classList.add("hidden")

    if (!this.validEmail(submittedEmail)) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.emailErrorTarget.innerHTML = "Please enter a valid email address"
      this.emailErrorTarget.classList.remove("hidden")
    }
  }

  checkValidPassword(event) {
    let submittedPassword = this.passwordTarget.value
    this.passwordErrorTarget.innerHTML = ""
    this.passwordErrorTarget.classList.add("hidden")

    if (submittedPassword.length == 0) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.passwordErrorTarget.innerHTML = "Please enter your password"
      this.passwordErrorTarget.classList.remove("hidden")
    }
  }

  checkValidNewPassword(event) {
    let submittedNewPassword = this.newPasswordTarget.value
    this.newPasswordErrorTarget.innerHTML = ""
    this.newPasswordErrorTarget.classList.add("hidden")

    if (!this.validPassword(submittedNewPassword)) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.newPasswordErrorTarget.innerHTML = "Please ensure your new password is at least 8 characters long and contains at least one number and one symbol"
      this.newPasswordErrorTarget.classList.remove("hidden")
    }
    else if (this.hasNewPasswordConfirmTarget) {
      let submittedNewPasswordConfirm = this.newPasswordConfirmTarget.value

      if (submittedNewPasswordConfirm != submittedNewPassword) {
        event.preventDefault()
        this.fireAuthFailEvent()

        this.newPasswordErrorTarget.innerHTML = "Please make sure the passwords match"
        this.newPasswordErrorTarget.classList.remove("hidden")
      }
    }
  }

  checkValidFirstName(event) {
    let submittedFirstName = this.firstNameTarget.value
    this.firstNameErrorTarget.innerHTML = ""
    this.firstNameErrorTarget.classList.add("hidden")

    if (submittedFirstName.length == 0) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.firstNameErrorTarget.innerHTML = "Please enter your first name"
      this.firstNameErrorTarget.classList.remove("hidden")
    }
  }

  checkValidLastName(event) {
    let submittedLastName = this.lastNameTarget.value
    this.lastNameErrorTarget.innerHTML = ""
    this.lastNameErrorTarget.classList.add("hidden")

    if (submittedLastName.length == 0) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.lastNameErrorTarget.innerHTML = "Please enter your last name"
      this.lastNameErrorTarget.classList.remove("hidden")
    }
  }

  checkValidCompanyName(event) {
    let submittedCompanyName = this.companyNameTarget.value
    this.companyNameErrorTarget.innerHTML = ""
    this.companyNameErrorTarget.classList.add("hidden")

    if (submittedCompanyName.length == 0) {
      event.preventDefault()
      this.fireAuthFailEvent()

      this.companyNameErrorTarget.innerHTML = "Please enter your company name"
      this.companyNameErrorTarget.classList.remove("hidden")
    }
  }

  validEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validPassword(password) {
    if (this.hasMinPasswordValue && (password.length < this.minPasswordValue)) return false // too short
    if (!password.match(/[0-9]+/)) return false // no number
    if (!password.match(/[-@±§!–#€£$%^&*()_+|~=`{}\[\]π:";'<>?,.\/]+/)) return false // no symbol
    return true
  }
}

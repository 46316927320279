import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['player', 'container']

  open(e) {
    e.preventDefault()

    this.playerTarget.classList.add('active')
    $('body').addClass('modal-shown')

    if (!this.hasContainerTarget) return

    let embed = e.currentTarget.getAttribute('data-video-embed-data')
    this.containerTarget.innerHTML = embed

    var iframe = $(this.containerTarget).find(`[data-oembed-type="video"] iframe:visible`)[0]
    if (iframe) {
      var video = iframe.getAttribute('src')
      iframe.setAttribute('src', video + '&autoplay=1&rel=0')
    }
  }

  close() {
    if (this.hasContainerTarget) {
      this.containerTarget.innerHTML = ''
    }

    if (this.playerTarget.classList.contains("active")) {
      this.playerTarget.classList.remove('active')
      $('body').removeClass('modal-shown')
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }
}

import lottie from 'lottie-web'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []
  static values = { url: String }

  connect() {
    lottie.loadAnimation({
      container: this.element,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      path: this.urlValue
    });
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['player']

  open(e) {
    e.preventDefault()

    this.playerTarget.classList.add('active')
    $('body').addClass('modal-shown')
  }

  close() {
    if (this.playerTarget.classList.contains("active")) {
      this.playerTarget.classList.remove('active')
      $('body').removeClass('modal-shown')
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['player']

  open() {
    this.playerTarget.classList.add('active')
    $('body').addClass('modal-shown')

    var iframe = $(this.element).find(`[data-oembed-type="video"] iframe:visible`)[0]
    if (iframe) {
      var video = iframe.getAttribute('src')
      iframe.setAttribute('src', video + '&autoplay=1&rel=0')
    }
  }

  close() {
    var iframe = $(this.element).find(`[data-oembed-type="video"] iframe:visible`)[0]
    if (iframe) {
      var video = iframe.getAttribute('src')
      iframe.setAttribute('src', video.replace('&autoplay=1', ''))
    }

    if (this.playerTarget.classList.contains("active")) {
      this.playerTarget.classList.remove('active')
      $('body').removeClass('modal-shown')
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'searchInput',
    'nameOutput',
    'formattedNameOutput',
    'latitudeOutput',
    'longitudeOutput',
    'northeastLatitudeOutput',
    'northeastLongitudeOutput',
    'southwestLatitudeOutput',
    'southwestLongitudeOutput',
  ]

  connect() {
    const precise = this.data.get('precise-lookup')

    if (this.hasSearchInputTarget) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.searchInputTarget,
        precise ? { types: ['address'] } : {}
      )
      google.maps.event.addListener(
        this.autocomplete,
        'place_changed',
        function () {
          var place = this.autocomplete.getPlace()
          if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert(
              'Sorry, but no results were available for your search.'
            )
            return
          }

          this.nameOutputTarget.value = place.formatted_address
          this.formattedNameOutputTarget.value = place.adr_address

          if (this.hasLatitudeOutputTarget) {
            this.latitudeOutputTarget.value = place.geometry.location.lat()
          }
          if (this.hasLongitudeOutputTarget) {
            this.longitudeOutputTarget.value = place.geometry.location.lng()
          }
          if (
            this.hasNortheastLatitudeOutputTarget &&
            this.hasNortheastLongitudeOutputTarget
          ) {
            this.northeastLatitudeOutputTarget.value = place.geometry.viewport
              .getNorthEast()
              .lat()
            this.northeastLongitudeOutputTarget.value = place.geometry.viewport
              .getNorthEast()
              .lng()
          }
          if (
            this.hasSouthwestLatitudeOutputTarget &&
            this.hasSouthwestLongitudeOutputTarget
          ) {
            this.southwestLatitudeOutputTarget.value = place.geometry.viewport
              .getSouthWest()
              .lat()
            this.southwestLongitudeOutputTarget.value = place.geometry.viewport
              .getSouthWest()
              .lng()
          }

          this.nameOutputTarget.dispatchEvent(new CustomEvent('change'))
        }.bind(this)
      )
    }
  }
}

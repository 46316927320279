import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  clickLink(event) {
    var el = event.target

    if (
      el.tagName === 'A' &&
      !el.isContentEditable &&
      el.host !== window.location.host
    ) {
      el.setAttribute('target', '_blank')
    }
  }
}

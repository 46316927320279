import { Controller } from 'stimulus'
import iFrameResize from 'iframe-resizer/js/iframeResizer'

export default class extends Controller {
  static targets = []

  connect() {
    iFrameResize({ log: false }, this.element)
  }
}

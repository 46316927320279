import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.toggleOnce = this.data.get('once') == 'true' || false
    if (this.toggleOnce) {
      this.toggledOnce = false
    }
  }

  toggle(event) {
    event.preventDefault()

    if (this.toggleOnce && this.toggledOnce) {
      return true
    }

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass)
    })

    if (this.toggleOnce && !this.toggledOnce) {
      this.toggledOnce = true
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nav", "content"]
  static values = { tab: Number }

  initialize() {
    this.params = new URLSearchParams(window.location.search)
  }

  connect() {
    this.selectedIndex = this.tabValue || 1
    if (this.params.has('tab')) {
      this.selectedIndex = Number(this.params.get('tab'))
    }

    this.updateCurrentTab()
  }

  select(event) {
    event.preventDefault()
    let $target = $(event.currentTarget)

    this.selectedIndex = $target.data('index')
    this.updateCurrentTab()
    $target.addClass('selected')
  }

  updateCurrentTab() {
    $(this.navTarget).children("a").removeClass('selected')
    $(this.navTarget).children("a:nth-child(" + this.selectedIndex + ")").addClass('selected')

    $(this.contentTarget).children("div.tab-section").hide()
    $(this.contentTarget).children("div.tab-section:nth-child(" + this.selectedIndex + ")").show()
  }
}

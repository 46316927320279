import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'card', 'errors']

  connect() {
    this.stripe = Stripe(this.data.get('public-key'))
    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://use.typekit.net/wcy2cwh.css',
        },
      ],
    })
    const style = JSON.parse(this.data.get('style'))

    this.card = elements.create('card', { style: style })
    this.card.mount(this.cardTarget)
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ''
    }
  }

  stripeTokenHandler(token) {
    const hiddenInput = document.createElement('input')

    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.submit()
  }

  submit(event) {
    event.preventDefault()

    if (window.testStripeToken && window.testStripeToken.length) {
      this.stripeTokenHandler(window.testStripeToken)
    } else {
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          console.log(result.error)

          this.errorsTarget.textContent = result.error.message
          setTimeout(function () {
            $('button[data-disable-with]').each(function () {
              Rails.enableElement(this)
            })
          }, 500)
        } else {
          this.stripeTokenHandler(result.token)
        }
      })
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = { disabled: String, enabled: String }
  
  disable(e) {
    if (this.hasButtonTarget) {
      // already disable
      if (this.buttonTarget.classList.contains('disabled')) {
        e.preventDefault()
        return false
      }

      this.buttonTarget.classList.add('disabled')
      let disabledValue = this.hasDisabledValue
        ? this.disabledValue
        : 'Submitting...'
      if (this.buttonTarget instanceof HTMLInputElement) {
        this.buttonTarget.value = disabledValue;
      } else {
        this.buttonTarget.innerHTML = disabledValue;
      }
    }
  }

  enable() {
    if (this.hasButtonTarget) {
      // already disable
      if (!this.buttonTarget.classList.contains('disabled')) {
        return false
      }

      this.buttonTarget.classList.remove('disabled')
      let enabledValue = this.hasEnabledValue
        ? this.enabledValue
        : 'Submit'
      if (this.buttonTarget instanceof HTMLInputElement) {
        this.buttonTarget.value = enabledValue;
      } else {
        this.buttonTarget.innerHTML = enabledValue;
      }
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["link"]

  tempDeactivateLink(e) {
    if (this.linkTarget.classList.contains("disabled")) {
      e.preventDefault()
      e.stopImmediatePropagation()
      return false
    } else {
      $(this.linkTarget).addClass("disabled")
      const $this = this;
      setTimeout(function() {
        $($this.linkTarget).removeClass("disabled")
      }, 15000)
    }
  }
}
import { Controller } from 'stimulus'
import ClipboardJS from 'clipboard'

export default class extends Controller {
  static targets = ['action']
  static values = { content: String }

  connect() {
    var $this = this

    var clipboard = new ClipboardJS(this.actionTarget, {
      text: function (trigger) {
        return $this.contentValue
      },
    })
    clipboard.on('success', function (e) {
      alert('Copied to your clipboard.')
    })
    clipboard.on('error', function (e) {
      alert('Could not copy - sorry!')
    })
  }
}

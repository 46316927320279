import { Controller } from 'stimulus'

export default class extends Controller {
  selectAll() {
    this.element.querySelectorAll('input:not([value="all"])').forEach(el => {
      el.checked = false
    })
  }

  select() {
    this.element.querySelectorAll('input[value="all"]').forEach(el => {
      el.checked = false
    })
  }
}

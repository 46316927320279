import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.timer = setTimeout(function () {
      $(this.element).fadeOut('slow')
    }.bind(this), 6000)
  }

  disconnect() {
    clearInterval(this.timer)
  }
}

// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  connect() {
    if (this.data.get('open-on-connect')) {
      this.openModal()
      this.autofocus()
    }

    this.queueCheckModalBodyClass()
  }

  disconnect() {
    $('body').removeClass('modal-shown')
  }

  openModal(e) {
    if (e) {
      e.preventDefault();
    }

    $(this.modalTarget).addClass('active')
    $('body').addClass('modal-shown')

    if (e !== undefined && e.detail && e.detail.type == "edit") {
      $('#modal-header-date').removeClass('lg:flex')
      $('#update-progress-date').text("Update").attr('data-type', "edit").attr('data-action', "click->modal#close")
      $('#date-header-title').text("Event Date")

      $('#modal-header-delivery').removeClass('lg:flex')
      $('#update-progress-delivery').text("Update").attr('data-type', "edit")
      $('#delivery-header-title').text("Delivery Style")
      $('#delivery-back').addClass('hidden')

      $('#modal-header-variants').removeClass('lg:flex')
      $('#update-progress-variants').text("Update").attr('data-type', "edit").attr('data-action', "click->modal#closeModal")
      $('#variants-header-title').text("Delivery Style")
      $('#variants-back').addClass('hidden')

      $('#modal-header-location').removeClass('lg:flex')
      $('#update-progress-location').text("Update").attr('data-type', "edit").attr('data-action', "click->modal#closeModal")
      $('#location-header-title').text("Location")
      $('#location-back').addClass('hidden')

      $('#modal-header-support').removeClass('lg:flex')
      $('#update-progress-support').text("Update").attr('data-type', "edit").attr('data-action', "click->modal#closeModal")
      $('#support-header-title').text("Support Level")
      $('#support-back').addClass('hidden')

      $('#modal-header-addons').removeClass('lg:flex')
      $('#update-progress-addons').text("Update").attr('data-type', "edit").attr('data-action', "click->modal#closeModal")
      $('#addons-header-title').text("Event Add-Ons")
      $('#addons-back').addClass('hidden')
    } else if (e !== undefined && e.detail && e.detail.type == "signIn") {
      const $msg = $('#sign_in_message', '.modal-content')
      if (e.detail.message) {
        $msg.removeClass('hidden').find('p.message').html(e.detail.message)
      } else {
        $msg.addClass('hidden').find('p.message').html('')
      }
      if (e.detail.email) {
        $('#user_email', '.modal-content').val(e.detail.email)
        $('#user_password', '.modal-content').focus()
      }
    } else {
      $('#modal-header-date').addClass('lg:flex')
      $('#update-progress-date').text("Next")

      $('#modal-header-delivery').addClass('lg:flex')
      $('#update-progress-delivery').text("Next")

      $('#modal-header-variants').addClass('lg:flex')
      $('#update-progress-variants').text("Next")

      $('#modal-header-location').addClass('lg:flex')
      $('#update-progress-location').text("Next")

      $('#modal-header-support').addClass('lg:flex')
      // sometimes support is the last frame
      if ($('#update-progress-support').attr('data-modal-change-text') === "true") {
        $('#update-progress-support').text("Next")
      }

      $('#modal-header-addons').addClass('lg:flex')
      $('#update-progress-addons').text("Finish")
    }
  }

  autofocus() {
    // autofocus
    const focusElement = this.element.querySelector('[autofocus]')
    if (focusElement) {
      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

  queueCheckModalBodyClass() {
    clearTimeout(window.modalWindowCheckTimeout)
    window.modalWindowCheckTimeout = setTimeout(this.checkModalBodyClass.bind(this), 100)
  }

  checkModalBodyClass() {
    if ($('.modal.active').length == 0 && $('body').hasClass('modal-shown')) {
      $('body').removeClass('modal-shown')
    }
  }

  stopAnyVideos() {
    this.element.querySelectorAll('iframe').forEach((el) => {
      var video = el.getAttribute('src')
      if (video) {
        el.setAttribute('src', video.replace('&autoplay=1', ''))
      }
    })
  }

  close() {
    if (this.modalTarget.classList.contains('active')) {
      if (this.data.get('persist')) {
        $(this.modalTarget).removeClass('active')
      } else {
        this.element.remove()
      }

      this.checkModalBodyClass()
      this.stopAnyVideos()
    }
  }

  closeModal(e) {
    if (
      (!$(e.target).hasClass('modal') &&
      !$(e.target).hasClass('close-modal') &&
      !$(e.target).hasClass('close-modal-button') &&
      !$(e.target).hasClass('progress-button') &&
      !$(e.target).hasClass('back-modal')) ||
      $(e.target).hasClass('disabled')
    )
      return

    e.preventDefault()
    this.close()
  }

  pushDown(e) {
    if ($('#modal_password_form.active').length != 0) {
      $(e.target).addClass('hidden')
      document.getElementById("modal_password_form").style.marginTop = "50%";
      document.getElementById("modal_password_form").classList.remove('fixed');
      document.getElementById("modal_password_form").classList.add('sticky');
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {

  enter(event) {
    event.preventDefault()
    $(event.currentTarget).parent().find('.wg-team-txt').css("display", "block");
  }

  leave(event) {
    event.preventDefault()
    $(event.currentTarget).parent().find('.wg-team-txt').css("display", "none");
  }
}

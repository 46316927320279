import { Controller } from 'stimulus'
let debounce = require('lodash/debounce')

export default class extends Controller {
  static targets = ["form", "content", "grid"]

  connect() {
    this.submit = debounce(this.submit, 500)
  }

  markAsLoading() {
    this.contentTargets.forEach(el => {
      el.classList.add("opacity-25")
    })
  }

  submit() {
    this.formTarget.classList.add("pointer-events-none")
    this.formTarget.requestSubmit()
  }

  scrollToGrid() {
    $('html, body').animate(
      { scrollTop: $(this.gridTarget).offset().top - 125 }, 700
    )
  }
}

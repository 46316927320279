import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map', 'modal', 'modalTitle']

  connect() {
    if (google.maps) {
      this.renderFirstResult()
    } 
    window.tippy('[data-tippy-content]')
  }

  renderFirstResult() {
    if ($('.preview').length < 1) return

    const previewButton = $('.preview')[0]
    this.renderMap(previewButton)
  }

  renderMap(elem) {
    if (!this.hasMapTarget) return

    const primaryHotspot = $(elem).data('primary-hotspot')
    const map = new google.maps.Map(this.mapTarget, {
      center: {
        lat: primaryHotspot[0],
        lng: primaryHotspot[1],
      },
      zoom: 14,
      streetViewControl: false,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'hybrid'],
      },
    })

    const cityCircle = new google.maps.Circle({
      strokeColor: '#2BB096',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2BB096',
      fillOpacity: 0.35,
      map,
      center: { lat: primaryHotspot[0], lng: primaryHotspot[1] },
      radius: 400,
    })
  }

  openModal(e) {
    e.preventDefault()
    const elem = e.currentTarget
    this.renderMap(elem)

    if (elem.dataset.modalise) {
      this.modalTitleTarget.innerHTML = elem.dataset.regionName
      this.modalTarget.classList.add('active')
      $('body').addClass('modal-shown')
    }

    return false
  }

  closeModal(e) {
    e.preventDefault()

    if (this.modalTarget.classList.contains("active")) {
      this.modalTarget.classList.remove('active')
      $('body').removeClass('modal-shown')
    }
  }
}

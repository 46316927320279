import { Controller } from 'stimulus'

// yes, this is gnarly, but it's actually doing a simple operation.
// it checks if the hubspot script tag is on the page, and if so, replaces it.
// this triggers a reload and rebind to ensure forms are submitted,
// even if they are loaded at a later point.
export default class extends Controller {
  connect() {
    if (window._readdingHS) { return; }
    window._readdingHS = true;

    window._hsp = null;
    window._hsq = null;
    window.__hsCollectedFormsDebug = null;

    let el = document.querySelector('[id^=CollectedForms]')
    if (el) {
      el.remove()

      let attributes = [
        "crossorigin",
        "data-leadin-portal-id",
        "data-leadin-env",
        "data-loader",
        "data-hsjs-portal",
        "data-hsjs-env",
        "data-hsjs-hublet"
      ]

      var c = document.createElement("script");
      c.id = el.id
      c.type = "text/javascript"
      c.src = el.src;
      attributes.forEach(e => {
        c.setAttribute(e, el.getAttribute(e))
      })

      var i = document.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(c, i);
    }
    let el2 = document.getElementById("hs-analytics")
    if (el2) {
      el2.remove()

      var c = document.createElement("script");
      c.id = el2.id
      c.type = "text/javascript"
      c.src = el2.src

      var n = document.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(c, n);
    }

    setTimeout(function() {
      window._readdingHS = false;
    }, 1000)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dailySummary', 'dailySummaryInput', 'dailySummaryAlways']

  connect() {
    this.validateInputs()
  }

  validateInputs() {
    if (this.dailySummaryInputTarget.checked) {
      $(this.dailySummaryAlwaysTarget)
        .removeClass('opacity-25')
        .removeClass('pointer-events-none')
    } else {
      $(this.dailySummaryAlwaysTarget).find('input').prop('checked', false)
      $(this.dailySummaryAlwaysTarget)
        .addClass('opacity-25')
        .addClass('pointer-events-none')
    }
  }
}

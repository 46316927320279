// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['automatic', 'manual']

  show_manual(event) {
    if ($(this.manualTarget).hasClass('hidden')) {
      $(this.manualTarget).removeClass('hidden')
      $(this.automaticTarget).addClass('hidden')
      $(event.currentTarget).text('Hide manual address entry')
    } else {
      $(this.manualTarget).addClass('hidden')
      $(this.automaticTarget).removeClass('hidden')
      $(event.currentTarget).text('Enter address manually')
    }
  }

  clear_validation() {
    $('.validation').text(null).addClass('hidden')
  }
}

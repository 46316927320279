import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["chooser"]

  connect() {
    if (this.hasChooserTarget) {
      this.teamId = $(this.chooserTarget).data('current-team')
      this.observer = new MutationObserver(this.teamChanged.bind(this))
      this.observer.observe(this.element, { childList: true, subtree: true })
    }
  }

  teamChanged() {
    window.feather.replace()

    if (this.teamId != $(this.chooserTarget).data('current-team')) {
      window.location.reload()
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    let newWidth = $(this.element).data('new-width')
    if (newWidth) {
      $(this.element).css('width', newWidth + '%')
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map', 'modal', 'time', 'name', 'localTime', 'select']

  connect() {
    if (google.maps) {
      this.selectRegion()
    }
  }

  selectRegion() {
    this.renderMap()
    this.renderTime()

    let selected = this.selectTarget.options[this.selectTarget.selectedIndex]

    // reset all items, but then set this one
    for (var i = 0; i < this.selectTarget.options.length; i++) {
      let option = this.selectTarget.options[i]
      let name = option.getAttribute("data-name")
      if (this.selectTarget.options.length > 1 && selected.value == option.value) {
        option.innerHTML = `${name} (+ ${this.selectTarget.options.length - 1} more)`
      } else {
        option.innerHTML = name
      }
    }

    let latitude = parseFloat(selected.getAttribute("data-latitude"))
    let longitude = parseFloat(selected.getAttribute("data-longitude"))
    this.circle.setCenter({
      lat: latitude,
      lng: longitude
    });
    this.map.setCenter(this.circle.getCenter());
  }

  renderTime() {
    let selected = this.selectTarget.options[this.selectTarget.selectedIndex]
    let name = selected.getAttribute("data-name")
    let time = selected.getAttribute("data-local-time")
    if (time && time.length) {
      this.nameTarget.innerHTML = name
      this.localTimeTarget.innerHTML = time
      this.timeTarget.classList.remove("hidden")
    } else {
      this.timeTarget.classList.add("hidden")
    }
  }

  renderMap() {
    if (!this.hasMapTarget) {
      return
    }

    if (!this.map) {
      this.map = new google.maps.Map(this.mapTarget, {
        zoom: 14,
        streetViewControl: false,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'hybrid'],
        },
      })
    }

    if (!this.circle) {
      this.circle = new google.maps.Circle({
        strokeColor: '#2BB096',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#2BB096',
        fillOpacity: 0.35,
        radius: 600,
        map: this.map,
      })
    }
  }

  openModal(e) {
    this.selectRegion()
    e.preventDefault()
    $(this.modalTarget).addClass('active')
    $('body').addClass('modal-shown')

    return false
  }

  closeModal(e) {
    e.preventDefault()
    if (
      !$(e.target).hasClass('modal') &&
      !$(e.target).hasClass('close-modal') &&
      !$(e.target).hasClass('close-modal-button')
    )
      return
    $(this.modalTarget).removeClass('active')
    $('body').removeClass('modal-shown')

    return false
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  initialize() {
    this.title = this.data.get('title')
    this.description = this.data.get('description')
    this.url = this.data.get('url')
    this.img = this.data.get('img')
  }

  share(event) {
    const type = $(event.currentTarget).data('type')

    switch (type) {
      case 'facebook':
        this.openUrl(
          `http://www.facebook.com/sharer/sharer.php?u=${this.url}&display=popup&quote=${this.description}`,
          555,
          400
        )
        break
      case 'twitter':
        this.openUrl(
          `http://twitter.com/share?&text=${this.title}&url=${this.url}`,
          650,
          300
        )
        break
      case 'linkedin':
        this.openUrl(
          `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
        )
        break
      case 'email':
        location.href = `mailto:?to=&subject=${this.title}&body=${this.url}`
        break
      default:
        return false
    }
  }

  openUrl(url, width, height) {
    var left, opt, top
    if (width == null) {
      width = 640
    }
    if (height == null) {
      height = 480
    }
    left = screen.width / 2 - width / 2
    top = screen.height * 0.3 - height / 2
    opt =
      'width=' +
      width +
      ',height=' +
      height +
      ',left=' +
      left +
      ',top=' +
      top +
      ',menubar=no,status=no,location=no'
    window.open(url, 'popup', opt)
    return false
  }
}

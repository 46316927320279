import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    const $this = this
    setTimeout(function () {
      $this.reconnect()
    }, 5000)
  }

  check() {
    const $this = this
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: `/my-events/${this.data.get('id')}/game_code`,
      success: function () {
        setTimeout(function () {
          $this.reconnect()
        }, 5000)
      },
      error: function () {},
    })
  }

  reconnect() {
    if (this.data.has('id-present')) {
      // the game_code is present, so stop with the ajax calls
    } else {
      this.check()
    }
  }
}

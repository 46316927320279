import { Controller } from 'stimulus'
import 'slick-carousel'

export default class extends Controller {
  static targets = ['slider', 'prev', 'next']

  connect() {
    $(this.sliderTarget).slick({
      prevArrow: this.prevTarget,
      nextArrow: this.nextTarget,
      dots: true,
      infinite: false,
      accessibility: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: false
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            arrows: false,
            infinite: true,
            focusOnSelect: false
          },
        },
      ],
    })

    $('.remote').each(function (index) {
      $(this).parent().parent().addClass('slick-remote')
    })
  
    $('.hybrid').each(function (index) {
      $(this).parent().parent().addClass('slick-hybrid')
    })
    
    $('.in-person').each(function (index) {
      $(this).parent().parent().addClass('slick-in-person')
    })
  }
  
  changeClasses(e) {
    $('.filter-category').each(function (index) {
      $(this).addClass('text-muted')
      $(this).removeClass('border-b-4 border-wg-green-300')
    })

    $(e.target).removeClass('text-muted')
    $(e.target).addClass('border-b-4 border-wg-green-300')
  }

  setAll(e) {
    $('.theme-slider').slick('slickUnfilter');
    this.changeClasses(e)
  }
  
  setVirtual(e) {
    $('.theme-slider').slick('slickUnfilter');
    $('.theme-slider').slick('slickFilter', '.slick-remote');
    this.changeClasses(e)
  }

  setHybrid(e) {
    $('.theme-slider').slick('slickUnfilter');
    $('.theme-slider').slick('slickFilter', '.slick-hybrid');
    this.changeClasses(e)
  }

  setInPerson(e) {
    $('.theme-slider').slick('slickUnfilter');
    $('.theme-slider').slick('slickFilter', '.slick-in-person');
    this.changeClasses(e)
  }

}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['participantCount', 'submit', 'minParticipantMsg']
  static values = { baseUrl: String, priceStructures: Array, themeName: String, isCore: Boolean }

  trackedFields(currencyCode, quote) {
    return {
      'participants': this.participantCount,
      'host': this.facilitatorRequired == 1,
      'currency': currencyCode,
      'price': quote,
      'themeName': this.themeNameValue
    }
  }

  connect() {
    window.feather.replace()
    this.realField = $(`#${this.data.get('realId')}`)
    this.participantCount = 0
    this.facilitatorRequired = this.usesFacilitator()
    this.calculateQuote(false)
    this.createUrl()

    mixpanel.track('Visited Product Page', {
      'themeName': this.themeNameValue
    });
  }

  countParticipants(e) {
    this.validateInputs(e)
    this.calculateQuote(true)
  }

  increaseParticipants(e) {
    var currentCount = parseInt($(this.participantCountTarget).val())
    $(this.participantCountTarget).val(currentCount + 1)
    if (isNaN(currentCount)) { $(this.participantCountTarget).val(0) }
    this.countParticipants($(this.participantCountTarget))
    if (this.participantCount > 0) this.minParticipantMsgTarget.classList.add('hidden')
  }

  decreaseParticipants(e) {
    var currentCount = parseInt($(this.participantCountTarget).val())
    $(this.participantCountTarget).val(currentCount - 1)
    if (isNaN(currentCount)) { $(this.participantCountTarget).val(1) }
    this.countParticipants($(this.participantCountTarget))
    if (this.participantCount > 0) this.minParticipantMsgTarget.classList.add('hidden')
  }

  validateInputs(e) {
    let input = e.target
    if (e.target == undefined) {
      input = e[0]
    }

    var min = input.getAttribute('min')
    if (!!min && input.value && input.value < parseFloat(min)) {
      input.value = min
    }

    var max = input.getAttribute('max')
    if (!!max && input.value && input.value > parseFloat(max)) {
      input.value = max
    }
  }

  checkbox(e) {
    const value = $(e.currentTarget).is(':checked')
    this.realField.prop('checked', value)
    
    this.calculateQuote(false)
  }

  currencySelectChange() {
    if ($('select option:contains(GBP)').is(':selected')) {
      $('#VAT').text('*excludes 20% VAT')
    }
    else {
      $('#VAT').text('')
    }

    $.each(this.priceStructuresValue, (index, countryData) => { 
      if ($('select option:contains(' + countryData["currencyCode"] + ')').is(':selected')) {
        $('#perParticipantCostText').text(countryData["symbol"] + countryData["perParticipantCost"] + " per person")
      }
    })
    
    this.calculateQuote(false)
  }

  usesFacilitator() {
    return $('#facilitator-checkbox').is(':checked') ? 1 : 0
  }

  calculateQuote(trackMixpanel) {   

    var currencyCode = ""
    var quote = ""

    $.each(this.priceStructuresValue, (index, countryData) => { 
      quote = this.calculateParticipantsCost(countryData) 
      if (quote > 0) quote += this.calculateFacilitatorCost(countryData)
      if (this.participantCount > 0) quote += this.calculateMinimumCost(quote, countryData)
      currencyCode = countryData["currencyCode"]

      this.displayQuote(countryData, quote)
      this.toggleSubmit(quote)
    })

    if (trackMixpanel == true) {
      mixpanel.track('Widget: Updated Participants', this.trackedFields(currencyCode, quote));
    }

    this.createUrl()
  }
  
  toggleSubmit(quote) {
    $(this.submitTarget).toggleClass('opacity-40', quote == 0)
  }

  displayQuote(countryData, quote) {
    if (this.isCoreValue == true) {
      $('select option:contains('+ countryData["currencyCode"] +')').text(`${countryData["symbol"]}${quote} ${countryData["currencyCode"]}`);
    } else { 
      $('#partner-quote').text(`${countryData["symbol"]}${quote} ${countryData["currencyCode"]}`)
    };
  }

  calculateMinimumCost(quote, countryData) {
    let quoteIncrease = 0
    if ((countryData["hasReducedPrice"] == true) && (quote < countryData["minimumCost"]))
      quoteIncrease += countryData["minimumCost"]
    
    return quoteIncrease
  }

  calculateParticipantsCost(countryData) {
    this.participantCount = parseInt($('#attendees-count').val())
    const participantsCost = countryData["perParticipantCost"] * this.participantCount
    
    if (participantsCost < countryData["minimumCost"] || isNaN(participantsCost)) {
      return 0
    }
    else {
      return participantsCost
    }
  }

  calculateFacilitatorCost(countryData) {
    this.facilitatorRequired = this.usesFacilitator()

    if (this.facilitatorRequired == 1) {
      return countryData["facilitatorCost"]
    }
    else {
      return 0
    }
  }

  createUrl() {
    let currency = "GBP"
    if (this.isCoreValue == true) {
      let selectWords = $('select option:selected').text().split(' ');
      currency = selectWords[1]
      this.url = `${this.baseUrlValue}&participants=${this.participantCount}&host=${this.facilitatorRequired}&currency=${currency}`
    } else {
      this.url = `${this.baseUrlValue}&participants=${this.participantCount}&host=${this.facilitatorRequired}`
    }
  }

  createOrder(e) {
    e.preventDefault()
    if (this.participantCount > 0) {
      this.minParticipantMsgTarget.classList.add('hidden')
      var currencyCode = ""
      var quote = ""

      this.createUrl()
    
      $.each(this.priceStructuresValue, (index, countryData) => { 
        if ($('select option:contains(' + countryData["currencyCode"] + ')').is(':selected')) {
          quote = this.calculateParticipantsCost(countryData) 
          quote += this.calculateFacilitatorCost(countryData)
          quote += this.calculateMinimumCost(quote, countryData)
          currencyCode = countryData["currencyCode"]
        }
      })
  
    mixpanel.track('Check Availability', this.trackedFields(currencyCode, quote), {send_immediately: true});
  
      window.location.href = this.url
      return true
    } else {
      this.minParticipantMsgTarget.classList.remove('hidden')
      return false
    }
  }
}
import { Controller } from 'stimulus'
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ["query", "form", "results", "searchIcon", "mobileSearchIcon", "mobileSearchBox", "mask"]
  static values = { "queryMinLength": Number }

  initialize() {
    this.searchSubmit = debounce(this.searchSubmit, 275).bind(this)
  }

  connect() {
    this.mobile = false
    this.basketButton = document.getElementById("basket_component")
    this.resetResultIndex()
    if(!this.hasQueryMinLengthValue) this.queryMinLengthValue = 4
  }

  queryKeyup(e) {
    if (e.keyCode == 40 && this.hasResults() == true) { // down pressed + we have some results to select
      this.selectNextResult()
    } else if (e.keyCode == 38 && this.hasResults() == true) { // up pressed + we have some results to select
      this.selectPreviousResult()
    } else {
      this.search(e)
    }
  }

  cancelWindowScrollIfHasResults(e) {
    if ((e.keyCode == 40 || e.keyCode == 38) && this.hasResults()) {
      e.preventDefault()
      e.stopImmediatePropagation()
      return false
    }
  }

  resetResultIndex() {
    this.selectedResultIndex = 0
  }

  getResultsTarget() {
    return this.mobile ? this.resultsTargets[1] : this.resultsTargets[0]
  }

  hasResults() {
    return $(this.getResultsTarget()).find('.site_search_result ').length > 0
  }

  selectNextResult() {
    const $results = $(this.getResultsTarget()).find('.site_search_result')
    const resultCount = $results.length

    this.selectedResultIndex = this.selectedResultIndex >= resultCount ? 1 : this.selectedResultIndex + 1

    const $result = $($results[(this.selectedResultIndex - 1)])
    const $button = $result.find('button').first()

    $button.focus()
    return true
  }

  selectPreviousResult() {
    const $results = $(this.getResultsTarget()).find('.site_search_result')
    const resultCount = $results.length

    this.selectedResultIndex = this.selectedResultIndex <= 1 ? resultCount : this.selectedResultIndex - 1

    const $result = $($results[(this.selectedResultIndex - 1)])
    const $button = $result.find('button').first()

    $button.focus()
    return true
  }

  resultButtonKeyup(e) {
    e.preventDefault()

    // Pressing Enter will activate the button and submit the form, otherwise....
    if (e.keyCode == 40) { // Down pressed
      this.selectNextResult()
    } else if(e.keyCode == 38) { // Up pressed
      this.selectPreviousResult()
    } else if(e.keyCode == 27) { // Esc pressed
      this.hideResults()
    }

    return true
  }

  search(e, forceSearch=false) {
    this.toggleSearchIcon()
    const queryTarget = this.mobile ? this.queryTargets[1] : this.queryTargets[0]
    
    if (e.keyCode == 27) { // Escape pressed
      queryTarget.blur(e)
      return true
    }

    const formTarget = this.mobile ? this.formTargets[1] : this.formTargets[0]
    const okToSubmit = (queryTarget.value.length > this.queryMinLengthValue) || (e.keyCode == 13) || forceSearch

    if(okToSubmit) {
      this.searchSubmit(formTarget)
      return true
    } else {
      e.preventDefault()
      this.hideResults()
      return false
    }
  }

  searchSubmit(formTarget) {
    formTarget.requestSubmit()
  }

  toggleSearchIcon() {
    if (this.queryTarget.value.length > 0) {
      this.hideSearchIcon()
    } else {
      this.showSearchIcon()
    }
  }

  blurQueryMobile(e) {
    this.blurQuery(e, true)
  }

  blurQuery(e, useBlurDelay=false) {
    if(e.relatedTarget === null && e.type != "click") {
      e.preventDefault()
      if(useBlurDelay) {
        window.setTimeout(() => this.hideResults(), 500)
      } else {
        this.hideResults()
      }
    }
  }

  hideResults() {
    this.getResultsTarget().innerHTML = ""
  }

  showSearchIcon() {
    this.searchIconTarget.classList.remove('hidden')
  }

  hideSearchIcon() {
    this.searchIconTarget.classList.add('hidden')
  }

  openMobileBox(e) {
    e.preventDefault()
    this.mobile = true
    this.basketButton.classList.add("hidden")
    this.mobileSearchIconTarget.classList.add('hidden')
    this.mobileSearchBoxTarget.classList.remove('hidden')
    this.maskTarget.classList.remove('hidden')
    this.queryTargets[1].focus()
  }

  closeMobileBox(e) {
    e.preventDefault()
    this.mobileSearchBoxTarget.classList.add('hidden')
    this.mobileSearchIconTarget.classList.remove('hidden')
    this.maskTarget.classList.add('hidden')
    this.basketButton.classList.remove("hidden")
  }

  clearMobileQuery(e) {
    e.preventDefault()
    this.queryTargets[1].value = ''
    this.hideResults()
    window.setTimeout(() => this.queryTargets[1].focus(), 350)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map']

  renderMap() {
    if (!this.hasMapTarget) {
      return
    }
    const primaryHotspot = JSON.parse(this.data.get('primary-hotspot'))

    var map = new google.maps.Map(this.mapTarget, {
      center: {
        lat: primaryHotspot[0],
        lng: primaryHotspot[1],
      },
      zoom: 14,
      streetViewControl: false,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'hybrid'],
      },
    })

    // add primary
    var marker = new google.maps.Marker({
      map: map,
      position: { lat: primaryHotspot[0], lng: primaryHotspot[1] },
      icon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
      },
    })
  }
}

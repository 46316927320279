import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['name', 'email', 'location', 'pageName', 'optIn', 'submit']

  connect() {
    this.validate()
    this.pageNameTarget.value = document.title
  }

  updateOptIn(e) {
    $(this.optInTargets).each(function () {
      $(this).prop('checked', $(this).val() == $(e.target).val())
    })
  }

  validate() {
    const hidden_location = this.data.get('hidden-location')

    var enabled = true
    enabled = enabled && !!this.nameTarget.value
    enabled = enabled && !!this.emailTarget.value
    if (!hidden_location) {
      enabled = enabled && !!this.locationTarget.value
    }
    // enabled = enabled && !!this.optInTarget.value;

    if (enabled) {
      this.submitTarget.classList.remove('disabled')
    } else {
      this.submitTarget.classList.add('disabled')
    }
  }
}

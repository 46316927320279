import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    setTimeout(() => {
      this.element.classList.remove("hidden")
    }, 5000)
  }

  accept() {
    this.setCookie(true)
    this.close()
  }

  decline() {
    mixpanel.opt_out_tracking();
    this.setCookie(false)
    this.close()
    location.reload()
  }

  toggle(e) {
    this.setCookie(e.target.checked)
    location.reload()
  }

  close() {
    this.element.classList.add('hidden')
  }

  setCookie(accepted) {
    // Expire cookie after 1 year
    let expiryDate = new Date(Date.now() + 365 * (1000 * 60 * 60 * 24))
    document.cookie = `accepted_cookies=${
      accepted ? 'yes' : 'no'
    }; expires=${expiryDate}; path=/`
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["url", "dropdownMenu", "removeEventForm"]

  openModal(e) {
    e.preventDefault()

    document.getElementById("basket-remove-order-modal").classList.add('active')

    const title = $(e.target).closest("turbo-frame").find(".title-to-copy").html()
    document.getElementById("basket-remove-order-title").innerHTML = title

    const urlId = $(e.target).closest("turbo-frame")[0].id.replace(/basket_order_/, "")
    let action = $("#basket-remove-form-url")[0].action

    document.getElementById("basket-remove-order-url-id").innerHTML = urlId
    let cleanAction = action.replace(/\?url_id=\w*/, "").replace(/&viewing_order=true/, "")
    if (cleanAction.includes("country_locale")) {
      $("#basket-remove-form-url")[0].action = cleanAction + `&url_id=${urlId}`
    } else {
      $("#basket-remove-form-url")[0].action = cleanAction + `?url_id=${urlId}`
    }

    if (window.location.href.includes(urlId)) {
      // switch to HTML submit if looking at the proposal for event we're deleting
      $("#basket-remove-form-url")[0].setAttribute('data-turbo', false)
      $("#basket-remove-form-url")[0].action = $("#basket-remove-form-url")[0].action + "&viewing_order=true"
    }

    $('body').addClass('modal-shown')

    return false
  }

  removeEvent() {
    this.removeEventFormTarget.requestSubmit()
  }

  // mobile
  showBasket(event) {
    $(this.dropdownMenuTarget).removeClass("hidden")
    $("#openBasketIcon")[0].setAttribute("data-action", "click->basket#hideBasket")
  }

  // mobile
  hideBasket(event) {
    $(this.dropdownMenuTarget).addClass("hidden")
    $("#openBasketIcon")[0].setAttribute("data-action", "click->basket#showBasket")
  }
}
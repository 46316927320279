import { Controller } from 'stimulus'
import lottie from "lottie-web";

export default class extends Controller {

	static targets = [
    'lottieContainer'
  ]
  
  snowfall = null;

  connect() {
  	this.snowfall = lottie.loadAnimation({
      container: this.lottieContainerTarget,
      renderer: 'svg',
      autoplay: true,
      loop: true,
      speed: 0.5,
      path: $(this.lottieContainerTarget).attr("path").replace("uk/", "").replace("usa/", "")
    });

    this.snowfall.setSpeed(0.5)

  }

}
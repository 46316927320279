import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "moneyOff",
    "moneyOffCurrency",
    "partnerId"
  ]

  connect() {
    var $this = this;
    if(this.hasPartnerIdTarget) {
      $(this.partnerIdTarget).on('change', function(e){
        e.stopImmediatePropagation();
        $this.toggleShowCurrency(e);
      });  
    }
  }


  keyup() {
    var start = this.element.selectionStart;
    var end = this.element.selectionEnd;
    this.element.value = this.element.value.toUpperCase();
    this.element.setSelectionRange(start, end);
  }

  percentageOffKeyup() {
    $(this.moneyOffTarget).val("");
  }

  toggleShowCurrency() {
    var e = document.getElementById("coupon_partner_id")
    var selectedPartner = e.options[e.selectedIndex].text
    if (selectedPartner === "All") {
      $(this.moneyOffCurrencyTarget).removeClass('hidden')
    } else {
      $(this.moneyOffCurrencyTarget).addClass('hidden')
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'emailInput', 'hiddenForm', 'hiddenEmailInput']

  connect() {
    this.check()
  }

  check() {
    const $this = this
    if ($this.hasFormatTarget) {
      $this.formTarget.requestSubmit()
    }

    if (!$this.hasHiddenFormTarget) {
      return
    }
    if ($('#secondary_modal').children().length > 0) {
      return
    }

    $this.hiddenEmailInputTargets.forEach(
      (input) => (input.value = $this.emailInputTarget.value)
    )
    setTimeout(function () {
      $this.hiddenFormTarget.requestSubmit()
    }, 200)
  }
}

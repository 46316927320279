import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'daysContainer',
    'days',
    'hoursContainer',
    'hours',
    'minutesContainer',
    'minutes',
    'secondsContainer',
    'seconds',
  ]

  pad(num, size) {
    var s = num + ''
    while (s.length < size) s = '0' + s
    return s
  }

  connect() {
    // Set the date we're counting down to
    var countDownDate = parseInt(this.data.get('target'))

    // Update the count down every 1 second
    var self = this
    this.x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Display the result in the element
      var str = ''
      self.daysTarget.innerHTML = days > 0 ? self.pad(days, 2) : '00'
      self.hoursTarget.innerHTML = hours > 0 ? self.pad(hours, 2) : '00'
      self.minutesTarget.innerHTML = minutes > 0 ? self.pad(minutes, 2) : '00'
      self.secondsTarget.innerHTML = seconds > 0 ? self.pad(seconds, 2) : '00'

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(self.x)
        $('.hide-after-countdown').addClass('hidden')
      }
      $(self.element).removeClass('invisible')
    }, 500)
  }

  disconnect() {
    clearInterval(this.x)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const params = new URL(window.location.href).searchParams;
    const inPerson = params.get('in-person')
    if (inPerson) {
      this.scrollToThemeBrowser()
      setTimeout(() => {
        this.select(null, 'in_person')
      }, 700)
    }
  }

  select(e = null, itemKey = null) {
    let key
    if (e && !itemKey) {
      key = e.currentTarget.getAttribute("data-key")
    } else if (!e && itemKey) {
      key = itemKey
    }

    document.querySelectorAll("[name='options[delivery_types][]']").forEach(el => {
      el.checked = (el.value == key)
    })
    document.querySelectorAll("[data-controller='theme-browser']").forEach(el => {
      const controller = this.application.getControllerForElementAndIdentifier(el, "theme-browser")
      controller.markAsLoading()
      controller.submit()
    })
  }

  scrollToThemeBrowser() {
    $('html, body').animate(
      { scrollTop: $('#theme_filter').offset().top - 100, },
      700,
      function () {
        window.animating = false
      }
    )

  }
}

import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = ['inviteFormFrame', 'modal']

  initLazyLoad() {
    let lazyLoadOptions = { elements_selector: '.lazy' }
    window.pageLazyLoad = new LazyLoad(lazyLoadOptions)
  }

  showLinkOrderModal(event) {
    this.openModal(event, 'link-order-form')
  }
  
  hideLinkOrderModal(event) {
    this.closeModal(event, 'link-order-form')
  }
  
  escHideLinkOrder(event) {
    if (event.key === 'Escape') this.closeModal(event, 'link-order-form')
  }
  
  openModal(event, targetClass) {
    event.preventDefault()
    
    let modalBox = this.modalTargets.find(function (elem) {
      return $(elem).hasClass(targetClass)
    })

    $(modalBox).addClass('active')
    $('body').addClass('modal-shown')
    return false
  }

  closeModal(event, targetClass) {
    event.preventDefault()

    let modalBox = this.modalTargets.find(function (elem) {
      return $(elem).hasClass(targetClass)
    })
    $(modalBox).removeClass('active')
    $('body').removeClass('modal-shown')
  }
}

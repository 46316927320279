// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

// This example controller works with specially annotated HTML like:

// <div class="relative" data-controller="dropdown">
//  <div data-action="click->dropdown#toggle click@window->dropdown#hide" role="button" class="inline-block select-none">
//    Open Dropdown
//  </div>
//  <div data-target="dropdown.menu" class="absolute right-0 mt-2 hidden">
//    <div class="bg-white shadow rounded border overflow-hidden">
//      Content
//    </div>
//  </div>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'selectedValueInput']

  connect() {
    window.feather.replace()
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle(event) {
    this.menuTarget.classList.toggle(this.toggleClass)

    if (this.data.has('connected-rounded')) {
      $(event.currentTarget).toggleClass('rounded-b-lg')
      if ($(event.currentTarget).hasClass('border-shadow-3')) {
        $(event.currentTarget).toggleClass('border-shadow-3 border-shadow-gray')
        $(event.currentTarget).addClass('remove-shadow-while-open')
      }
      if (
        $(event.currentTarget).hasClass('rounded-b-lg') &&
        $(event.currentTarget).hasClass('remove-shadow-while-open')
      ) {
        $(event.currentTarget)
          .addClass('border-shadow-3')
          .removeClass('border-shadow-gray')
        $(event.currentTarget).removeClass('remove-shadow-while-open')
      }
    }
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass)
      if (
        this.data.has('connected-rounded') &&
        this.hasSelectedValueInputTarget
      ) {
        $(this.selectedValueInputTarget).toggleClass('rounded-b-lg')
        if (
          $(this.selectedValueInputTarget).hasClass('remove-shadow-while-open')
        ) {
          $(this.selectedValueInputTarget)
            .addClass('border-shadow-3')
            .removeClass('border-shadow-gray')
          $(this.selectedValueInputTarget).removeClass(
            'remove-shadow-while-open'
          )
        }
      }
    }
  }
}

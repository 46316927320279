import { Controller } from 'stimulus'
import LazyLoad from 'vanilla-lazyload'

export default class extends Controller {
  static targets = ['inviteFormFrame', 'memberTableFrame', 'modal']

  initialize() {
    this.params = new URLSearchParams(window.location.search)
  }

  connect() {
    this.memberTableObserver = new MutationObserver(
      this.memberTableFrameMutated.bind(this)
    )
    if (this.hasMemberTableFrameTarget) {
      this.memberTableObserver.observe(this.memberTableFrameTarget, {
        childList: true,
      })
    }

    if (this.hasInviteFormFrameTarget) {
      this.inviteFormObserver = new MutationObserver(
        this.inviteFormFrameMutated.bind(this)
      )
      this.inviteFormObserver.observe(this.inviteFormFrameTarget, {
        childList: true,
      })
    }

    if (this.params.has('show_invite')) this.showInviteDialog()
  }

  inviteFormFrameMutated(mutations) {
    let $form = $(mutations[0].target)
    let hasErrors = !!$form.find('.is-invalid').length
    if (!hasErrors) {
      this.closeModal(null, 'invite-form')
      this.reloadMembersTable()
    }
  }

  reloadMembersTable() {
    if (this.hasMemberTableFrameTarget) {
      let src = this.memberTableFrameTarget.getAttribute('src')
      this.memberTableFrameTarget.setAttribute('src', src)
    }
  }

  memberTableFrameMutated() {
    window.feather.replace()
    this.initLazyLoad()
  }

  initLazyLoad() {
    let lazyLoadOptions = { elements_selector: '.lazy' }
    window.pageLazyLoad = new LazyLoad(lazyLoadOptions)
  }

  showSwapRolesModal(event) {
    const userId = $(event.currentTarget).data("user-id")
    const teamId = $(event.currentTarget).data("team-id")
    const url = $(document.getElementById("admin-swap-role-url")).data("url")
    document.getElementById("admin_swap_team_user_role").src = url + `?user_id=${userId}&team_id=${teamId}`
    
    this.openModal(event, 'swap-role')
  }

  showInviteFormModal(event) {
    if ($(event.currentTarget).data('order-id')) {
      $(this.inviteFormFrameTarget)
        .find('#user_order_id')
        .val($(event.currentTarget).data('order-id'))
    }
    if ($(event.currentTarget).data('team-id')) {
      $(this.inviteFormFrameTarget)
        .find('#user_team_id')
        .val($(event.currentTarget).data('team-id'))
    }

    this.openModal(event, 'invite-form')
  }

  hideInviteFormModal(event) {
    this.closeModal(event, 'invite-form')
  }

  hideSwapRoleFormModal(event) {
    this.closeModal(event, 'swap-role')
  }

  escHideInvite(event) {
    if (event.key === 'Escape') this.closeModal(event, 'invite-form')
  }

  escHideSwapRole(event) {
    if (event.key === 'Escape') this.closeModal(event, 'swap-role')
  }

  showCreateTeamModal(event) {
    this.openModal(event, 'create-team')
  }

  hideCreateTeamModal(event) {
    this.closeModal(event, 'create-team')
  }

  escHideTeamModal(event) {
    if (event.key === 'Escape') this.closeModal(event, 'create-team')
  }

  openModal(event, targetClass) {
    event.preventDefault()

    let modalBox = this.modalTargets.find(function (elem) {
      return $(elem).hasClass(targetClass)
    })
    $(modalBox).addClass('active')
    $('body').addClass('modal-shown')

    return false
  }

  closeModal(event, targetClass) {
    event.preventDefault()

    let modalBox = this.modalTargets.find(function (elem) {
      return $(elem).hasClass(targetClass)
    })
    $(modalBox).removeClass('active')
    $('body').removeClass('modal-shown')
  }

  disconnect() {
    if (this.hasMemberTableFrameTarget) {
      this.memberTableObserver.disconnect()
      delete this.memberTableObserver
    }

    if (this.hasInviteFormFrameTarget) {
      this.inviteFormObserver.disconnect()
      delete this.inviteFormObserver
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'menuOpen', 'menuClose', 'progressBar']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  open() {
    this.menuOpenTarget.classList.add(this.toggleClass)
    this.menuCloseTarget.classList.remove(this.toggleClass)
    this.menuTarget.classList.remove(this.toggleClass)
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.classList.add(this.toggleClass)
    }
    window.document.body.classList.add("overflow-hidden")
  }

  close() {
    this.menuOpenTarget.classList.remove(this.toggleClass)
    this.menuCloseTarget.classList.add(this.toggleClass)
    this.menuTarget.classList.add(this.toggleClass)
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.classList.remove(this.toggleClass)
    }
    window.document.body.classList.remove("overflow-hidden")
  }
}

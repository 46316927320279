import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['textBody', 'navBody']

  initialize() {
    //
    let sections = []
    const h1Sections = $(this.textBodyTarget).find('h1')
    const h2Sections = $(this.textBodyTarget).find('h2')
    const h3Sections = $(this.textBodyTarget).find('h3')
    const h4Sections = $(this.textBodyTarget).find('h4')
    const $this = this

    if (sections.length <= 1) {
      sections.pop()
      h1Sections.each(function (id, obj) {
        if ($(obj).text()) {
          sections.push(obj)
        }
      })
    }

    if (sections.length <= 1) {
      sections.pop()
      h2Sections.each(function (id, obj) {
        if ($(obj).text()) {
          sections.push(obj)
        }
      })
    }

    if (sections.length <= 1) {
      sections.pop()
      h3Sections.each(function (id, obj) {
        if ($(obj).text()) {
          sections.push(obj)
        }
      })
    }

    if (sections.length <= 1) {
      sections.pop()
      h4Sections.each(function (id, obj) {
        if ($(obj).text()) {
          sections.push(obj)
        }
      })
    }

    if (sections.length == 1) {
      sections.pop()
    }

    // prevent item duplication whenever you go back/forward in the browser
    $($this.navBodyTarget).empty()

    //
    let SectionIndex = []
    let SectionTitle = []

    sections.forEach(function (obj, idx) {
      // add unique id to header
      $(obj).attr('id', 'header-' + idx)
      $(obj).attr('class', 'section-title')

      SectionIndex.push(idx)
      SectionTitle.push($(obj).text())
    })

    SectionIndex.forEach(function (idx) {
      let title = SectionTitle[idx]
      title = title.replace(/^\d+\./g, '')
      title = title.trim()

      let number = ('0' + (idx + 1)).slice(-2)
      $($this.navBodyTarget).append(
        `<div data-action="click->blog#scrollToHeader" data-id="${idx}" class="cursor-pointer opacity-50 hover:opacity-100 text-xl"><div class="inline-block" style="padding-right: 2rem; width: 50px">${number}.</div>${title}</div>`
      )
    })

    // if sections is empty
    if (sections.length == 0) {
      $('#nav-bar').removeClass('lg:w-4/12')
      $('#nav-bar').removeClass('lg:block')
      $('#content').removeClass('lg:w-8/12')
      $('#content').addClass('lg:w-12/12')
    }
  }

  onScroll() {
    if (window.animating) return false

    const $this = this
    const windowScroll = $(window).scrollTop()
    const sectionTitles = document.querySelectorAll('.section-title')
    let scrolledHeadings = []

    sectionTitles.forEach(function (element) {
      let element_index = $(element).attr('id').split('-').slice(-1)[0]
      let element_position = $(element).position().top - 200

      if (element_position <= windowScroll) {
        scrolledHeadings.push(element_index)
      }
    })

    let latest_heading = Math.max.apply(Math, scrolledHeadings)

    $($this.navBodyTarget)
      .children()
      .removeClass('opacity-100')
      .addClass('opacity-50')
    if (isFinite(latest_heading)) {
      $($this.navBodyTarget)
        .children(`[data-id="${latest_heading}"]`)
        .addClass('opacity-100')
        .removeClass('opacity-50')
    }
  }

  scrollToHeader(event) {
    const id = $(event.currentTarget).data('id')
    window.animating = true
    $('html, body').animate(
      {
        scrollTop: $(`#header-${id}`).offset().top - 200,
      },
      700,
      function () {
        window.animating = false
      }
    )

    $(this.navBodyTarget)
      .children()
      .each(function () {
        $(this).removeClass('opacity-100').addClass('opacity-50')
      })

    $(event.currentTarget).addClass('opacity-100').removeClass('opacity-50')
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {

  static values = {theme: String}

  connect() {
  }

  openCustomQuestionsModal(e) {
    e.preventDefault()

    const event = new CustomEvent("open-addon-info-custom-questions-modal")
    window.dispatchEvent(event)

    mixpanel.track('Theme Filter: Open Custom Questions', {
      'themeName': e.target.parentElement.dataset.value
    });
  }

  openCustomBrandingModal(e) {
    e.preventDefault()

    const event = new CustomEvent("open-addon-info-custom-branding-modal")
    window.dispatchEvent(event)

    mixpanel.track('Theme Filter: Open Custom Branding', {
      'themeName': e.target.parentElement.dataset.value
    });
  }

  clickBookNow(e) {
    mixpanel.track('Theme Filter: Book Now', {
      'themeName': e.target.dataset.value
    });
  }

  clickMoreInfo(e) {
    mixpanel.track('Theme Filter: More Info', {
      'themeName': e.target.dataset.value
    });
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  toggle(event) {
    $(event.currentTarget).siblings('.accordion-body').toggleClass('hidden')
    $(event.currentTarget).find('.accordion_arrow').toggleClass('flip')
  }
}

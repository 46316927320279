import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['warningMessage']

  initialize() {
    this.realField = $(`#${this.data.get('realId')}`)
  }

  checkbox(event) {
    const value = $(event.currentTarget).is(':checked')
    this.realField.prop('checked', value).attr('checked', value)

    if (this.hasWarningMessageTarget) {
      if (value) {
        $(this.warningMessageTarget).removeClass('hidden')
      } else {
        $(this.warningMessageTarget).addClass('hidden')
      }
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "userEmail",
    "submitButton",
    "collaboratorWarning"
  ]
  static values = {
    userEmail: String,
    isCollaboratorFormType: Boolean
  }

  connect() {
    this.checkEmail();
  }

  checkEmail() {
    const email = this.userEmailTarget.value;
    const allTeamUsers = $(document.getElementById("all-team-users")).data("all-team-users")
    if (email == this.userEmailValue || (!this.isCollaboratorFormTypeValue && allTeamUsers.includes(email))) {
      $(this.collaboratorWarningTarget).removeClass('hidden')
      if (!this.isCollaboratorFormTypeValue && allTeamUsers.includes(email)) {
        $(this.collaboratorWarningTarget).text("A team member with that email address already exists")
      } else {
        $(this.collaboratorWarningTarget).text("You cannot add yourself as a collaborator")
      }
      $(this.submitButtonTarget).addClass('opacity-30')
      this.submitButtonTarget.disabled = true
    } else {
      $(this.collaboratorWarningTarget).addClass('hidden')
      $(this.submitButtonTarget).removeClass('opacity-30')
      this.submitButtonTarget.disabled = false
    }
  }
}
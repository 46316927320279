import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['field']

  connect() {
    if (this.hasFieldTarget) {
      this.fieldTarget.focus()
      if (this.onMobileDevice()) {
        const yOffset = -300
        window.scrollTo({
          top:
            this.fieldTarget.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset,
          behavior: 'smooth',
        })
      }
    }
  }

  onMobileDevice() {
    let deviceCheck = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent.toLowerCase()
    )

    return deviceCheck
  }
}
